@import './bv-color.scss';

$content-border-color: lighten(map-get($colors, secondary), 70%);

ion-content {
	&.content-md {
		background-color: $body-background-color;
	}
	.content {
		padding-top: 20px;
		border-left: 1px solid $content-border-color;
		border-right: 1px solid $content-border-color;
		background-color: $content-background-color;
		height: 100%;
	}
}

ion-nav {
	font-family: BureauVeritas-ExtBdUltraCond,Arial,sans-serif;
}

[clickable] {
	cursor: pointer;
}

.ellipsis {
	width: 500px;
	font-family: 'Courier New', Courier, monospace;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}