/* You can add global styles to this file, and also import other style files */
/*

 * App Global CSS

 * ----------------------------------------------------------------------------

 * Put style rules here that you want to apply globally. These styles are for

 * the entire app and not just one component. Additionally, this file can be

 * used as an entry point to import other CSS/Sass files to be included in the

 * output CSS.

 * For more information on global stylesheets, visit the documentation:

 * https://ionicframework.com/docs/layout/global-stylesheets

 */



/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";



/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import 'variables';
@import 'bv-color';
@import 'bv-alert';
@import 'bv-button';
@import 'bv-form';
@import 'bv-table';
@import 'bv-global';

@font-face {
    font-family: 'Roboto';
    src: url("assets/fonts/Roboto-Regular.ttf");
}

body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

ion-footer {
    ion-col {
        border: 0;
        text-align: center;
    }
}

ion-col {
    font-size: 0.9rem;

    //font-weight: 700;
    ion-label {
        &.value {
            font-weight: 700;
        }
    }
}

.bv-modal-gsit-shipment-metadata {
    .modal-wrapper {
        height: 500px;
    }
}

div.segment-activated ion-segment-button {
    color: map-get($colors , 'primary');
    border: none;
}

ion-grid.filters {
    background: #e2e2e2;

    ion-row {
        &.header {
            ion-col {
                color: map-get($colors, darkgray);
            }
        }

        &.body {
            @extend .bv-form;
            margin-top: 0;

            ion-col {

                ion-grid {
                    padding: 0;

                    ion-row {
                        ion-col {
                            padding: 0;

                            .datetime-text {
                                padding-top: 3px
                            }
                        }
                    }
                }

                ion-item {

                    ion-select,
                    ion-input,
                    ion-datetime,
                    .item-inner {
                        border-bottom-width: 0 !important;
                        box-shadow: unset !important;
                    }
                }

                span:only-child {
                    padding-bottom: 13px;
                }

                .autocomplete {
                    background-color: $body-background-color;
                    pointer-events: auto;
                    position: absolute;
                    top: calc(100% + 1px);
                    z-index: 5;
                    width: 100%;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0 0 10px 1px transparentize(map-get($colors, dark), 0.6);
                }
            }
        }
    }
}


ion-grid.metadata {
    padding: 0;

    ion-row {
        &.header {
            background-color: map-get($colors, lightgray);
            color: map-get($colors, light);

            ion-col {
                ion-label {
                    white-space: pre-wrap;

                    &:not(.value) {
                        font-size: 1.3rem;
                        margin: 0;
                        color: map-get($colors, primary);
                    }

                    &.value {
                        margin: 4px 4px 0 0;
                    }
                }
            }
        }

        &.body {
            border-top: 1px solid map-get($colors, grayhead);
            margin-top: -1px;

            ion-col {
                border-right: 1px solid map-get($colors, grayhead);
                border-bottom: 1px solid map-get($colors, grayhead);
                padding: 20px;

                &:nth-child(3n+0) {
                    border-right-width: 0;
                }

                ion-label {
                    white-space: pre-wrap;

                    &:not(.value) {
                        color: var(--ion-color-medium);
                        font-size: .8rem;
                        margin: 0;
                    }

                    &.value {
                        margin: 4px 4px 0 0;
                        color: map-get($colors, black);
                        font-weight: normal;
                    }

                    &.link {
                        margin: 4px 4px 0 0;
                        color: map-get($colors, primary);
                        font-weight: bold;
                        font-size: 1rem;
                    }
                }
            }

            &:last-child {
                ion-col {
                    border-right-width: 0;
                }
            }
        }
    }
}

ion-label {
    --color: var(--ion-color-medium) !important;
}

