.bv-button {
	border-radius: 0px;
	height: 3.5rem !important;
	font-size: 1.4rem;
	font-weight:bold;
	white-space: normal;
	box-shadow: unset;
}
.bv-button-primary {
	background-color: map-get($colors, primary);
	color: map-get($colors, light);
	border: 2px solid map-get($colors, light);
}

// button {

// 	@extend .bv-button;
// 	@extend .bv-button-primary;

//     &.button-round {
//     	border-radius: 64px !important;
//     }
//     &.button-full {
//     	border-radius: 0px !important;
//     }
//     &.button-large {
//     	height: 5rem !important;
//         font-size: 2rem !important;
// 	}
//     &.button-small {
//     	height: 2.4em !important;
//         font-size: 1.5rem !important;
// 	}
//     &.button-clear {
//     	box-shadow: none;
// 	}
// 	&.dark {
//     	color: map-get($colors, dark);
//         border: 2px solid map-get($colors, dark);
// 	}
// }
