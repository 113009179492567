@import 'bv-color.scss';

.bv-alert.alert-md, .bv-alert.alert-ios, .bv-alert.alert-wp {

    .alert-button-group {
		justify-content: center;
		padding: 16px 0 16px 0;

		button.alert-button {
			min-width: 70px;
			text-align: center;
			border-radius: 5px;
			font-size: 15px;

			flex: none;
			height: auto;
			margin: 0px 8px;
			padding: 7px 10px;

			.button-inner {
				justify-content: center;
			}

			&.button-no {
				background-color: map-get($colors, dark);
				color: white;
			}

			&.button-yes {
				background-color: map-get($colors, primary);
				color: white;
			}
		}

		.button-red {
			background-color: map-get($colors, primary);
			color: white;
		}

		.not-disabled-red {
			background-color: map-get($colors, primary);
			opacity: 0.4;
			color: white;
			pointer-events: none;
			cursor: default;
		}
	}

    .alert-head {
		background-color: map-get($colors, secondary);
		color: map-get($colors, light);
		text-align: center;
		padding: 12px 16px;
		border-bottom: 3px solid;
		border-image: $bv-color-gradient 30;

        .alert-title {
			margin: 0;
			font-size: 1.7rem;
			font-weight: 600;
        }
    }

    .alert-message {
		color: black;
		font-size: 1.5rem;
		font-weight: bold;
		padding:12px;
        text-align: center;
		margin-top: 16px;
		overflow-y:auto;
		.text-left {
			text-align: left;
		}
        .text-red {
            color: map-get($colors, primary, base);
		}
		.large {
			font-size: 2rem;
		}
		ul {
        	margin: 15px 0px 0px 0px;
            padding: 0px;
            li {
                text-align: left;
            }
		}
		.info {
			ul {
				margin: 0 0 0 40px;
			}
			display: flex;
			align-items: center;
			padding-left: 30px;
			.icomoon-information {
				font-size: 30px;
			}
			.padding {
				padding: 0 10px;
			}
		}
    }

    .alert-wrapper {
    	min-width:300px;
	}

	.center {
		margin: auto;
	}
}
.picker-wrapper {
	top: 0;
}
