$colors: (
	//primary:    #488aff,
	primary:    #FC3650,
	secondary:  #333333,
	danger:     #f53d3d,
	light:      white, //#f4f4f4,
	dark:       #333333,
	gray: 		#7f7f7f,
	darkgray: 	#786e63,
	lightgray: 	#9b9b9b,
	gainsboro: 	#d8d8d8,
	grayhead: 	#EBEAE6,
	yellow : 	gold,
	red:     	#FC3650,
	black: 		#000000,
	light-black: #333333,
	white: 		#ffffff,
	extralightgray: #fcfcfc,
	seafoam: #47FFAF,
	seaweed: #447950
);

// Gradient
$bv-color-gradient-start: #21e8d8;
$bv-color-gradient-end: #9146fe;
$bv-color-gradient: linear-gradient(to right, $bv-color-gradient-start, $bv-color-gradient-end);

// Header
$header-background-color: map-get($colors, white);
$header-font-color: map-get($colors, dark);

// Body
$body-background-color: map-get($colors, white);

// Content
$content-background-color: map-get($colors, white);
