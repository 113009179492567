.bv-table-header {
	font-size: 1.4rem;
	font-weight: bold;
}

ion-grid.bv-table {
	margin-top: 30px;

	ion-row {
		border-bottom: 1px solid map-get($colors, grayhead);

		&:first-child {
			border-top: 1px solid map-get($colors, secondary);
		}

		&:last-child {
			border-bottom: 1px solid map-get($colors, secondary);
		}

		&.toolbar {
			border-bottom: 3px solid map-get($colors, primary);

			.sort {
				text-align: right;
			}

			ion-col[col-1] {
				text-align: right;
			}
		}

		&.header {
			@extend .bv-table-header;
		}

		&.body {

			//height: 250px;
			//overflow-y: scroll;
			ion-col[col-1] {
				text-align: right;
			}

			ion-grid {
				ion-row {
					height: 40px;
					vertical-align: middle;
					border-left: 3px solid transparent;

					&:first-child {
						border-top: 0;
					}

					&:last-child,
					&.header {
						border-bottom: 0;
					}

					&:hover {
						// background-color: lighten(map-get($colors, primary), 30%);
						color: map-get($colors, primary);
						border-left: 3px solid map-get($colors, primary);
						border-bottom: 1px solid map-get($colors, primary);
						transition: border 0.8s linear 0.2s;
					}
				}
			}

			&.details {

				ion-row {
					ion-col {
						--padding-start: 0;
					}
				}

			}
		}

		&.pagination {
			ion-col {
				&.pages {
					text-align: center;
					font-size: 1.3em;

					a {
						font-weight: bold;
						color: map-get($colors, secondary);
						text-decoration: none;

						&.activated {
							color: map-get($colors, primary);
						}

						&:hover {
							color: map-get($colors, primary);
						}
					}
				}
			}
		}
	}
}

ion-list.bv-table {
	padding: 0 5px;

	>ion-item {
		--padding-start: 0;
		--inner-padding-end: 0;

		&.header {
			border-bottom: 1px solid map-get($colors, secondary);

			ion-grid {
				@extend .bv-table-header;
			}
		}

		&.summary {
			&:hover {
				--background: #fcfcfc;
			}
		}

		&.details {
			>ion-row {
				>ion-col {
					padding: 0 !important;
				}
			}
		}

		&.selected {
			box-shadow: 0px 0px 5px 0px rgba(map-get($colors, gray), 0.75);
			border-left: 1px solid map-get($colors, grayhead);
			border-right: 1px solid map-get($colors, grayhead);

			&.summary {
				margin-top: 5px;
				border-top: 1px solid map-get($colors, grayhead);
				--background: #fcfcfc;
			}

			&.details {
				margin-bottom: 5px;
			}

		}
	}
}