.bv-form {
	.form-error:empty {
		display: none;
	}
	.form-error {
		display: inline;
        font-size: 1.2rem;
		color: map-get($colors, danger);
		margin-left: 20px;
	}
	ion-row {
		background-color: map-get($colors, white);
		border-left-width: 0;
		border-right-width: 0;
		margin-top: -1px;
		&.border-top-only {
			border-width: 1px 0px 0px 0px;
		}
		&.no-border {
			border-width: 0px;
		}
		.list > .item-block:first-child {
			border:0px;
		}
		.list > .item-block:last-child {
			border:0px;
		}
	}
}

@media (min-width: map-get($grid-breakpoints, md)) {
	.bv-form {
		margin-top: 40px;
		padding:0px;
		ion-row {
			border-left-width: 1px;
			border-right-width: 1px;
		 }
	 }
 }
